import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import React from "react";
import TeaserCard from "../components/teaserCard";
import { getLocalizedTags } from "./translation";

export const dtrOptions = {
  renderMark: {
    [MARKS.BOLD]: text => <b className="font-weight-bold">{text}</b>,
    [MARKS.ITALIC]: text => <i className="font-weight-normal">{text}</i>,
  },

  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const {
        embedLink,
        title,
        reader,
        visibleTags,
        topic,
        postDateTime,
        thumbnailLandscape,
      } = node.data.target.fields;
      return (
        // <p>VIDEO</p>
        <>
          <TeaserCard
            video={embedLink}
            className="articleVideo"
            backgroundImage={
              thumbnailLandscape.fields.imageLandscape.fields.file.url
            }
            richTextOrigin={true}
          />
          <br></br>
        </>
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const type = node.data.target.fields.file.contentType.split("/")[0];
      return type === "image" ? (
        <figure style={{ display: "inline-block" }}>
          <img
            style={{ maxWidth: "100%" }}
            src={node.data.target.fields.file.url}
            alt={node.data.target.fields.description}
          />
          <figcaption style={{ textAlign: "right" }}>
            {node.data.target.fields.description}
          </figcaption>
        </figure>
      ) : null;
    },
    [INLINES.ASSET_HYPERLINK]: node => {
      return (
        <a href={node.data.target.fields.file.url}>{node.content[0].value}</a>
      );
    },
    [INLINES.ENTRY_HYPERLINK]: node => {
      // Not supported
      return null;
    },
  },
};
